import { endOfDay, format, isBefore, isDate, isValid, startOfDay } from 'date-fns';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';

import { LANGUAGE_IDS } from './trackLanguageInfo';

const getDateDefaults = {
  start: { defaultHours: '00', defaultMinutes: '00', seconds: '00', ms: '000' },
  end: { defaultHours: '23', defaultMinutes: '59', seconds: '59', ms: '999' },
};

const getDate = (date, ofDay) => {
  if (!date) {
    return ofDay === 'start' ? startOfDay(new Date()) : endOfDay(new Date());
  }
  if (isDate(date)) return date;
  const { defaultHours, defaultMinutes, seconds, ms } = getDateDefaults[ofDay];
  const [dateStr, timeStr] = date.split('T');
  const year = dateStr.slice(0, 4);
  const month = dateStr.slice(4, 6) - 1; // Note: Month is 0-indexed. Date format: yyyyMMdd
  const day = dateStr.slice(6, 8);
  const hours = timeStr && timeStr.slice(0, 2) ? timeStr.slice(0, 2) : defaultHours;
  const minutes = timeStr && timeStr.slice(2, 4) ? timeStr.slice(2, 4) : defaultMinutes;

  return new Date(year, month, day, hours, minutes, seconds, ms);
};

const getDateString = (date = new Date()) => (typeof date === 'string' ? date : Filter.serializeDate(date));

class DateRange {
  static _lastId = 0;
  #id = 0;

  /**
   * Represents a date range. Defaults to the current date on the local timezone.
   * @class
   * @param {string|Date} - The start date of the range. If it's a string, it must be a valid Date string in the format "yyyyMMdd".
   * @param {string|Date} - The end date of the range. If it's a string, it must be a valid Date string in the format "yyyyMMdd".
   * @param {string} [tz="local timezone"] - A timezone for date range consideration. Must be an IANA timezone name string in format "Region-Country".
   * @throws {Error} Throws an error if either start or end is not a valid Date string or Date instance. Or is TZ is invalid. Or if end is earlier than start
   */
  constructor(start, end, tz) {
    const startDate = getDate(start, 'start');
    const endDate = getDate(end, 'end');

    if (!isValid(startDate)) throw new Error(`start must be a valid Date string or Date instance but was: ${start}`);

    if (!isValid(endDate)) throw new Error(`end must be a valid Date string or Date instance but was: ${end}`);

    if (isBefore(endDate, startDate)) throw new Error('end date must be equal or after start date');

    this.tz = tz?.replaceAll('-', '/') ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcStartDate = zonedTimeToUtc(startDate, this.tz);
    const utcEndDate = zonedTimeToUtc(endDate, this.tz);

    if (!isValid(utcStartDate) || !isValid(utcEndDate))
      throw new Error(`tz must be an IANA timezone name string in format "Region_Country" but was: ${tz}`);

    const localStartDate = utcToZonedTime(utcStartDate, this.tz);
    const localEndDate = utcToZonedTime(utcEndDate, this.tz);

    this.startDateString = getDateString(startDate);
    this.startDate = localStartDate;
    this.utcStartDate = utcStartDate;
    this.endDateString = getDateString(endDate);
    this.endDate = localEndDate;
    this.utcEndDate = utcEndDate;

    this.#id = ++DateRange._lastId;
  }

  toString() {
    return (
      `[DateRange@${this.#id}] ` +
      JSON.stringify(
        {
          tz: this.tz,
          startDate: formatInTimeZone(this.startDate, this.tz, 'yyyy-MM-dd HH:mm:ss zzz'),
          startDateString: this.startDateString,
          utcStartDate: this.utcStartDate.toISOString(),
          endDate: formatInTimeZone(this.endDate, this.tz, 'yyyy-MM-dd HH:mm:ss zzz'),
          endDateString: this.endDateString,
          utcEndDate: this.utcEndDate.toISOString(),
        },
        null,
        2
      )
    );
  }
}

class MultiSelectId {
  #val = null;
  #validIds = null;

  constructor(validIds, ids) {
    this.#validIds = validIds.reduce((obj, key) => ({ ...obj, [key]: key }), {});
    this.value = ids;
  }

  set value(newValue) {
    let parsedValue = null;

    if (typeof newValue === 'string' && newValue.length > 0) {
      const val = newValue.split('-').reduce((acc, id) => {
        const opt = this.#validIds[id];
        return typeof opt === 'undefined' ? acc : [...acc, opt];
      }, []);
      parsedValue = val;
    } else if (Array.isArray(newValue)) {
      parsedValue = newValue.filter((id) => this.#validIds[id] !== undefined);
    }

    this.#val = parsedValue?.length > 0 ? parsedValue : null;
  }

  get value() {
    return this.#val;
  }

  get serialized() {
    return Boolean(this.value) ? this.value.join('-') : null;
  }

  toString() {
    return `[MultiSelectId] value: ${this.value}, serialized: ${this.serialized}, #validIds: ${this.#validIds}`;
  }
}

export default class Filter {
  #devices = null;
  #translation = null;
  #langIds = null;
  #speechIds = null;
  #transcription = null;

  constructor({ startDate, endDate, tz, languageIds, speechIds, transcription, translation, devices } = {}) {
    this.dateRange = new DateRange(startDate, endDate, tz);
    this.#langIds = new MultiSelectId(LANGUAGE_IDS, languageIds);
    this.#speechIds = new MultiSelectId(['yes', 'no', 'unknown'], speechIds);
    this.#transcription = new MultiSelectId(['yes', 'no'], transcription);
    this.#translation = new MultiSelectId(['yes', 'no'], translation);
    this.devices = devices;
  }

  get startDate() {
    return this.dateRange.startDate;
  }

  get endDate() {
    return this.dateRange.endDate;
  }

  get tz() {
    return this.dateRange.tz;
  }

  get utcStartDate() {
    return this.dateRange.utcStartDate;
  }

  get utcEndDate() {
    return this.dateRange.utcEndDate;
  }

  get languageIds() {
    return this.getLanguageIds().value;
  }

  getLanguageIds() {
    return this.#langIds;
  }

  get speechIds() {
    return this.getSpeechIds().value;
  }

  getSpeechIds() {
    return this.#speechIds;
  }

  get transcription() {
    return this.getTranscription().value;
  }

  getTranscription() {
    return this.#transcription;
  }

  get translation() {
    return this.getTranslation().value;
  }

  getTranslation() {
    return this.#translation;
  }

  set devices(value) {
    if (typeof value === 'string') {
      this.#devices = value.split('-');
    } else if (Array.isArray(value)) {
      this.#devices = value;
    } else {
      this.#devices = null;
    }
  }

  get devices() {
    return this.#devices;
  }

  get query() {
    const serialized = {
      startDate: this.dateRange.startDateString,
      endDate: this.dateRange.endDateString,
      tz: this.dateRange.tz.replaceAll('/', '-'),
    };

    if (this.languageIds) {
      serialized.languageIds = this.#langIds.serialized;
    }

    if (this.speechIds) {
      serialized.speechIds = this.#speechIds.serialized;
    }

    if (this.transcription) {
      serialized.transcription = this.#transcription.serialized;
    }

    if (this.translation) {
      serialized.translation = this.#translation.serialized;
    }

    if (this.devices) {
      serialized.devices = this.devices.join('-');
    }

    return serialized;
  }

  static serializeDate(date) {
    return format(date, `yyyyMMdd'T'HHmm`);
  }
}
