import {
  Collection,
  COLLECTION_TIMEZONE_ERROR_MSG,
  COLLECTION_TRACKS_ERROR_MSG,
  isValidCollection,
} from '../entities/collection';
import { validateRequired } from '../utils/validations';

export class CollectionListingDTO {
  id = null;
  title = null;
  createdBy = null;
  trackIds = null;
  tracksCount = null;
  duration = null;
  createdAt = null;
  updatedAt = null;
  deleted = null;

  constructor(args) {
    this.#validate(args);

    this.id = args.id;
    this.title = args.title;
    this.createdBy = args.createdBy;
    this.duration = args.duration;
    this.createdAt = args.createdAt;
    this.updatedAt = args.updatedAt;
    this.deleted = args.deleted;

    if (args.tracks) {
      this.trackIds = args.tracks.map(({ id }) => id);
      this.tracksCount = args.tracks.length;
    } else {
      this.trackIds = args.trackIds;
      this.tracksCount = args.trackIds.length;
    }

    Object.freeze(this);
  }

  #validate(args) {
    if (isValidCollection(args)) return;

    try {
      Collection.validateConstructorArgs(args);
    } catch (error) {
      if ([COLLECTION_TRACKS_ERROR_MSG, COLLECTION_TIMEZONE_ERROR_MSG].indexOf(error.message) === -1) throw error;
    }

    validateRequired({ trackIds: args.trackIds });
  }
}
