import { validateRequired } from '../utils/validations';

class TrackLanguageInfo {
  constructor({ key, languageDetected }) {
    validateRequired({ key, languageDetected });

    this.id = key;
    this.languageId = languageDetected;
  }
}

export const makeTrackLanguageInfo = (args) => Object.freeze(new TrackLanguageInfo(args));

export const isValidTrackLanguageInfo = (arg) => arg instanceof TrackLanguageInfo;

export const LANGUAGES = Object.freeze({
  ar: 'Arabic',
  ru: 'Russian',
  unknown: 'Unknown',
  seeded_en: 'English',
});

export const LANGUAGE_IDS = Object.freeze(Object.keys(LANGUAGES));
