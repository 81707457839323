import { getRequiredEnvVar } from '@cherry/utils';

// Only Env Vars with `NEXT_PUBLIC_` prefix are available in the Browser: https://nextjs.org/docs/basic-features/environment-variables#exposing-environment-variables-to-the-browser
const userPoolId = getRequiredEnvVar(process.env.COGNITO_USER_POOL_ID, 'COGNITO_USER_POOL_ID');

const userPoolWebClientId = getRequiredEnvVar(
  process.env.COGNITO_USER_POOL_WEB_CLIENT_ID,
  'COGNITO_USER_POOL_WEB_CLIENT_ID'
);

const domain = getRequiredEnvVar(process.env.COOKIE_DOMAIN, 'COOKIE_DOMAIN');
const secure = getRequiredEnvVar(process.env.NODE_ENV, 'NODE_ENV') === 'production';

const awsConfig = {
  ssr: true,
  Auth: {
    region: 'us-east-1',
    userPoolId,
    userPoolWebClientId,
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain,
      // OPTIONAL - Cookie path
      path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 1,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      sameSite: 'lax',
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure,
    },
  },
};

export default awsConfig;
