import { Collection } from '../entities/collection';

export default class GetCollectionById {
  #trackRepo = null;
  #collectionRepo = null;

  constructor(trackRepo, collectionRepo) {
    if (!trackRepo) {
      throw new TypeError('First argument must be an instance of a Track Repository');
    }

    if (!collectionRepo) {
      throw new TypeError('Second argument must be an instance of a Collection Repository');
    }

    this.#trackRepo = trackRepo;
    this.#collectionRepo = collectionRepo;
  }

  /**
   * @param {number} collectionId - The ID of the collection to retrieve.
   * @param {boolean} deleted - Flag indicating if trying to get a deleted collection.
   * @returns {Promise<Collection>} - A promise that resolves to the retrieved collection.
   * @throws {Error} - Throws an error if no collection is found or if the collection is deleted.
   */
  async run(collectionId, deleted = false) {
    const collectionRow = await this.#collectionRepo.findById(collectionId);

    if (!collectionRow) throw new Error(`No collection found with id: ${collectionId}`);

    if (deleted && !collectionRow.deleted) {
      throw new Error(`Collection is NOT marked as deleted. id: ${collectionId}`);
    }

    if (!deleted && collectionRow.deleted) {
      throw new Error(`Collection is marked as deleted. id: ${collectionId}`);
    }
    const tracks = await this.#trackRepo.findAllById(collectionRow.trackIds);
    const createdAt = collectionRow.createdAt.getTime();
    const updatedAt = collectionRow.updatedAt.getTime();
    return new Collection({ ...collectionRow, createdAt, updatedAt, tracks });
  }
}
