import { validateRequired } from '../utils/validations';

class DenoisedTrack {
  constructor({ key, bucketName }) {
    validateRequired({ key, bucketName });

    this.id = key;
    this.denoisedBucket = bucketName;
  }
}

export const makeDenoisedTrack = (args) => Object.freeze(new DenoisedTrack(args));

export const isValidDenoisedTrack = (arg) => arg instanceof DenoisedTrack;
