import { useState } from 'react';

const useLocalStorage = (key, initialValue) => {
  const [state, setState] = useState(() => {
    if (typeof window === 'undefined') return initialValue;

    const value = window.localStorage.getItem(key);
    if (!value) return initialValue;
    return typeof value === 'object' ? JSON.parse(value) : value;
  });

  const setValue = (value) => {
    if (typeof window === 'undefined') return;

    let valueToStore = value instanceof Function ? value(state) : value;
    valueToStore = valueToStore === 'object' ? JSON.stringify(valueToStore) : value;
    window.localStorage.setItem(key, valueToStore);

    setState(value);
  };

  return [state, setValue];
};

export default useLocalStorage;
