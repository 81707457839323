import { Collection } from '../entities/collection';
import GetCollectionById from './GetCollectionById';

export default class RemoveTracksFromCollection {
  #trackRepo = null;
  #collectionRepo = null;
  #getCollectionById = null;

  constructor(trackRepo, collectionRepo) {
    if (!trackRepo) {
      throw new TypeError('First argument must be an instance of a Track Repository');
    }

    if (!collectionRepo) {
      throw new TypeError('Second argument must be an instance of a Collection Repository');
    }

    this.#trackRepo = trackRepo;
    this.#collectionRepo = collectionRepo;
    this.#getCollectionById = new GetCollectionById(trackRepo, collectionRepo);
  }

  /**
   * @param {number} collectionId - The ID of the collection to retrieve.
   * @param {Array<string>} trackIds - The IDs of the tracks to be removed from the given collection
   * @returns {Promise<Collection>} - A promise that resolves to the updated collection.
   * @throws {Error} - Throws an error if no collection is found or if the collection is deleted.
   */
  async run(collectionId, trackIds) {
    const collection = await this.#getCollectionById.run(collectionId);
    const remainingTrackIds = collection.tracks.map((t) => t.id).filter((id) => !trackIds.includes(id));
    const tracks = await this.#trackRepo.findAllById(remainingTrackIds);

    const updatedCollection = new Collection({
      ...collection,
      tracks,
    });

    await this.#collectionRepo.upsert(updatedCollection);

    return updatedCollection;
  }
}
