import { validateRequired } from '../utils/validations';
import { isValidDenoisedTrack } from './denoisedTrack';
import { isValidTrackLanguageInfo } from './trackLanguageInfo';
import { isValidTrackSpeechInfo } from './trackSpeechInfo';

class Track {
  constructor({
    key,
    src,
    base,
    bucketName,
    createtime,
    hash,
    duration,
    fileSize,
    transcription,
    translation,
    denoised,
    speechInfo,
    languageInfo,
  }) {
    validateRequired({ key, src, bucketName, duration, fileSize, createtime });
    if (denoised) isValidDenoisedTrack(denoised);
    if (speechInfo) isValidTrackSpeechInfo(speechInfo);
    if (languageInfo) isValidTrackLanguageInfo(languageInfo);

    this.id = key;
    this.device = src;
    this.baseName = base;
    this.bucket = bucketName;
    this.createdAt = formatCreatedAt(createtime);
    this.hashInfo = hash;
    this.duration = parseInt(duration);
    this.fileSize = fileSize;
    this.transcription = transcription;
    this.translation = translation;
    this.denoised = denoised;
    this.speechInfo = speechInfo;
    this.languageInfo = languageInfo;
  }
}

export const makeTrack = (params) => Object.freeze(new Track(params));

export const mergeTrack = (track, params) => {
  if (!isValidTrack(track)) throw new TypeError('first argument must be a valid track');
  return makeTrack({
    key: params.id || track.id,
    src: params.device || track.device,
    base: params.baseName || track.baseName,
    bucketName: params.bucket || track.bucket,
    createtime: params.createdAt || track.createdAt,
    hash: params.hash || track.hash,
    duration: params.duration || track.duration,
    fileSize: params.fileSize || track.fileSize,
    transcription: params.transcription || track.transcription,
    translation: params.translation || track.translation,
    denoised: params.denoised || track.denoised,
    speechInfo: params.speechInfo || track.speechInfo,
    languageInfo: params.languageInfo || track.languageInfo,
  });
};

export const isValidTrack = (arg) => arg instanceof Track;

function formatCreatedAt(createtime) {
  const onlyDigits = /^\d+$/.test(createtime);
  try {
    return new Date(onlyDigits ? parseInt(createtime) : Date.parse(createtime)).toISOString();
  } catch (error) {
    throw new Error('Track must have a valid createtime. Cause: ' + error.message);
  }
}
