export default class DeleteOwnCollections {
  #collectionRepo = null;

  constructor(collectionsRepo) {
    if (!collectionsRepo) {
      throw new TypeError('Argument must be an instance of a Collection Repository');
    }
    this.#collectionRepo = collectionsRepo;
  }

  /**
   *
   * @param {string} createdBy
   * @param {Array<string>} toDeleteIds
   */
  async run(createdBy, toDeleteIds) {
    const listingDTO = await this.#collectionRepo.listByIds(toDeleteIds);

    if (listingDTO.length === 0) throw new Error(`No collections found to delete: ${toDeleteIds}`);

    const unauthorized = listingDTO.find((dto) => dto.createdBy !== createdBy);

    if (unauthorized)
      throw new Error(`Cannot delete collections not owned by the user. Collection ID: ${unauthorized.id}`);

    await this.#collectionRepo.deleteByIds(createdBy, toDeleteIds);
  }
}
