import { validateRequired } from '../utils/validations';

class TrackSpeechInfo {
  constructor({ key }, hasSpeech) {
    validateRequired({ key, hasSpeech });
    if (!isValidHasSpeechValue(hasSpeech)) {
      throw new Error(`Invalid value: ${hasSpeech} for hasSpeech property.`);
    }

    this.id = key;
    this.hasSpeech = hasSpeech;
  }
}

export const HAS_SPEECH_VALUES = Object.freeze({
  UNKNOWN: 'UNKNOWN',
  YES: 'YES',
  NO: 'NO',
});

export const isValidHasSpeechValue = (hasSpeech) => {
  return !!HAS_SPEECH_VALUES[hasSpeech];
};

export const isValidTrackSpeechInfo = (arg) => arg instanceof TrackSpeechInfo;

export const makeTrackSpeechInfo = (params, hasSpeech) => Object.freeze(new TrackSpeechInfo(params, hasSpeech));
