import { CollectionListingDTO } from '../dtos/collectionListing';
import { isValidTrack } from './track';

export const COLLECTION_TRACKS_ERROR_MSG = 'tracks is a required non-empty Array of Tracks';
export const COLLECTION_TIMEZONE_ERROR_MSG = 'timezone is a required valid IANA string';

export class Collection {
  constructor({
    id = null,
    title,
    description,
    timezone,
    createdBy,
    tracks,
    createdAt = Date.now(),
    updatedAt = Date.now(),
    deleted = false,
  }) {
    Collection.validateConstructorArgs({ id, title, description, timezone, createdBy, tracks, createdAt });

    const duration = tracks.reduce((dur, track) => dur + track.duration, 0);

    this.id = id;
    this.title = title;
    this.description = description;
    this.timezone = timezone;
    this.createdBy = createdBy;
    this.tracks = tracks;
    this.tracksCount = tracks.length;
    this.duration = duration;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.deleted = deleted;
    Object.freeze(this);
  }

  toCollectionListingDTO() {
    return new CollectionListingDTO(this);
  }

  static validateConstructorArgs({
    id = null,
    title,
    description,
    timezone,
    createdBy,
    tracks,
    createdAt = Date.now(),
  } = {}) {
    if (!title) throw new Error('title is a required string');
    if (description && typeof description !== 'string') throw new Error('description is an optional string');
    if (!createdBy) throw new Error('createdBy is a required string');
    if (typeof createdAt !== 'number' || createdAt < 0) throw new Error('createdAt must be a positive number');
    if (id && (typeof id !== 'number' || id < 0)) throw new Error('id must be a positive number');
    if (!timezone || !Collection.isValidIanaTimezone(timezone)) throw new Error(COLLECTION_TIMEZONE_ERROR_MSG);

    if (Array.isArray(tracks)) {
      if (tracks.length === 0) {
        throw new Error(COLLECTION_TRACKS_ERROR_MSG);
      }
      if (tracks.find((track) => !isValidTrack(track))) {
        throw new Error(COLLECTION_TRACKS_ERROR_MSG);
      }
    } else {
      throw new Error(COLLECTION_TRACKS_ERROR_MSG);
    }
  }

  static isValidIanaTimezone(timeZone) {
    try {
      new Intl.DateTimeFormat('en-US', { timeZone });
      return true;
    } catch (e) {
      return false;
    }
  }
}

export const isValidCollection = (arg) => arg instanceof Collection;

export const makeCollection = (params) => new Collection(params);
